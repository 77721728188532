import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
};

/**
 * Account is an BNB address, with a list of all vToken markets the account has
 * participated in, along with liquidation information.
 *
 */
export type Account = {
  __typename?: 'Account';
  /** Count user has been liquidated */
  countLiquidated: Scalars['Int'];
  /** Count user has liquidated others */
  countLiquidator: Scalars['Int'];
  /** True if user has ever borrowed */
  hasBorrowed: Scalars['Boolean'];
  /** User BNB address */
  id: Scalars['ID'];
  /** Array of VTokens user is in */
  tokens: Array<AccountVToken>;
};


/**
 * Account is an BNB address, with a list of all vToken markets the account has
 * participated in, along with liquidation information.
 *
 */
export type AccountTokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountVToken_Filter>;
};

/**
 * AccountVToken is a single account within a single vToken market, with data such
 * as interest earned or paid
 *
 */
export type AccountVToken = {
  __typename?: 'AccountVToken';
  /** Relation to user */
  account: Account;
  /** The value of the borrow index upon users last interaction */
  accountBorrowIndex: Scalars['BigDecimal'];
  /** Block number this asset was updated at in the contract */
  accrualBlockNumber: Scalars['BigInt'];
  /** True if user is entered, false if they are exited */
  enteredMarket: Scalars['Boolean'];
  /** Concatenation of VToken address and user address */
  id: Scalars['ID'];
  /** Relation to market */
  market: Market;
  /** Current borrow balance stored in contract (exclusive of interest since accrualBlockNumber) */
  storedBorrowBalance: Scalars['BigDecimal'];
  /** Symbol of the vToken */
  symbol: Scalars['String'];
  /** Total amount underlying borrowed, exclusive of interest */
  totalUnderlyingBorrowed: Scalars['BigDecimal'];
  /** Total amount of underling redeemed */
  totalUnderlyingRedeemed: Scalars['BigDecimal'];
  /** Total amount underlying repaid */
  totalUnderlyingRepaid: Scalars['BigDecimal'];
  /** Total amount of underlying supplied */
  totalUnderlyingSupplied: Scalars['BigDecimal'];
  /** Transactions data */
  transactions: Array<AccountVTokenTransaction>;
  /** VToken balance of the user */
  vTokenBalance: Scalars['BigDecimal'];
};


/**
 * AccountVToken is a single account within a single vToken market, with data such
 * as interest earned or paid
 *
 */
export type AccountVTokenTransactionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVTokenTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountVTokenTransaction_Filter>;
};

/**
 * Auxiliary entity for AccountVToken
 *
 */
export type AccountVTokenTransaction = {
  __typename?: 'AccountVTokenTransaction';
  account: AccountVToken;
  block: Scalars['BigInt'];
  id: Scalars['ID'];
  logIndex: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  tx_hash: Scalars['Bytes'];
};

export type AccountVTokenTransaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<AccountVToken_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<AccountVTokenTransaction_Filter>>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  logIndex?: InputMaybe<Scalars['BigInt']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']>>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<AccountVTokenTransaction_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tx_hash?: InputMaybe<Scalars['Bytes']>;
  tx_hash_contains?: InputMaybe<Scalars['Bytes']>;
  tx_hash_gt?: InputMaybe<Scalars['Bytes']>;
  tx_hash_gte?: InputMaybe<Scalars['Bytes']>;
  tx_hash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tx_hash_lt?: InputMaybe<Scalars['Bytes']>;
  tx_hash_lte?: InputMaybe<Scalars['Bytes']>;
  tx_hash_not?: InputMaybe<Scalars['Bytes']>;
  tx_hash_not_contains?: InputMaybe<Scalars['Bytes']>;
  tx_hash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum AccountVTokenTransaction_OrderBy {
  Account = 'account',
  AccountAccountBorrowIndex = 'account__accountBorrowIndex',
  AccountAccrualBlockNumber = 'account__accrualBlockNumber',
  AccountEnteredMarket = 'account__enteredMarket',
  AccountId = 'account__id',
  AccountStoredBorrowBalance = 'account__storedBorrowBalance',
  AccountSymbol = 'account__symbol',
  AccountTotalUnderlyingBorrowed = 'account__totalUnderlyingBorrowed',
  AccountTotalUnderlyingRedeemed = 'account__totalUnderlyingRedeemed',
  AccountTotalUnderlyingRepaid = 'account__totalUnderlyingRepaid',
  AccountTotalUnderlyingSupplied = 'account__totalUnderlyingSupplied',
  AccountVTokenBalance = 'account__vTokenBalance',
  Block = 'block',
  Id = 'id',
  LogIndex = 'logIndex',
  Timestamp = 'timestamp',
  TxHash = 'tx_hash'
}

export type AccountVToken_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  accountBorrowIndex?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_gt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_gte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  accountBorrowIndex_lt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_lte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_not?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrowIndex_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  accrualBlockNumber?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  accrualBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  accrualBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  and?: InputMaybe<Array<InputMaybe<AccountVToken_Filter>>>;
  enteredMarket?: InputMaybe<Scalars['Boolean']>;
  enteredMarket_in?: InputMaybe<Array<Scalars['Boolean']>>;
  enteredMarket_not?: InputMaybe<Scalars['Boolean']>;
  enteredMarket_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<AccountVToken_Filter>>>;
  storedBorrowBalance?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_gt?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_gte?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  storedBorrowBalance_lt?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_lte?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_not?: InputMaybe<Scalars['BigDecimal']>;
  storedBorrowBalance_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  totalUnderlyingBorrowed?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingBorrowed_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_not?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingBorrowed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingRedeemed?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingRedeemed_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_not?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRedeemed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingRepaid?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingRepaid_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_not?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingRepaid_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingSupplied?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUnderlyingSupplied_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_not?: InputMaybe<Scalars['BigDecimal']>;
  totalUnderlyingSupplied_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  transactions_?: InputMaybe<AccountVTokenTransaction_Filter>;
  vTokenBalance?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_gt?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_gte?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  vTokenBalance_lt?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_lte?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_not?: InputMaybe<Scalars['BigDecimal']>;
  vTokenBalance_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum AccountVToken_OrderBy {
  Account = 'account',
  AccountBorrowIndex = 'accountBorrowIndex',
  AccountCountLiquidated = 'account__countLiquidated',
  AccountCountLiquidator = 'account__countLiquidator',
  AccountHasBorrowed = 'account__hasBorrowed',
  AccountId = 'account__id',
  AccrualBlockNumber = 'accrualBlockNumber',
  EnteredMarket = 'enteredMarket',
  Id = 'id',
  Market = 'market',
  MarketAccrualBlockNumber = 'market__accrualBlockNumber',
  MarketBlockTimestamp = 'market__blockTimestamp',
  MarketBorrowIndex = 'market__borrowIndex',
  MarketBorrowRate = 'market__borrowRate',
  MarketCash = 'market__cash',
  MarketCollateralFactor = 'market__collateralFactor',
  MarketExchangeRate = 'market__exchangeRate',
  MarketId = 'market__id',
  MarketInterestRateModelAddress = 'market__interestRateModelAddress',
  MarketName = 'market__name',
  MarketReserveFactor = 'market__reserveFactor',
  MarketReserves = 'market__reserves',
  MarketSupplyRate = 'market__supplyRate',
  MarketSymbol = 'market__symbol',
  MarketTotalBorrows = 'market__totalBorrows',
  MarketTotalSupply = 'market__totalSupply',
  MarketUnderlyingAddress = 'market__underlyingAddress',
  MarketUnderlyingDecimals = 'market__underlyingDecimals',
  MarketUnderlyingName = 'market__underlyingName',
  MarketUnderlyingPrice = 'market__underlyingPrice',
  MarketUnderlyingPriceUsd = 'market__underlyingPriceUSD',
  MarketUnderlyingSymbol = 'market__underlyingSymbol',
  StoredBorrowBalance = 'storedBorrowBalance',
  Symbol = 'symbol',
  TotalUnderlyingBorrowed = 'totalUnderlyingBorrowed',
  TotalUnderlyingRedeemed = 'totalUnderlyingRedeemed',
  TotalUnderlyingRepaid = 'totalUnderlyingRepaid',
  TotalUnderlyingSupplied = 'totalUnderlyingSupplied',
  Transactions = 'transactions',
  VTokenBalance = 'vTokenBalance'
}

export type Account_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  countLiquidated?: InputMaybe<Scalars['Int']>;
  countLiquidated_gt?: InputMaybe<Scalars['Int']>;
  countLiquidated_gte?: InputMaybe<Scalars['Int']>;
  countLiquidated_in?: InputMaybe<Array<Scalars['Int']>>;
  countLiquidated_lt?: InputMaybe<Scalars['Int']>;
  countLiquidated_lte?: InputMaybe<Scalars['Int']>;
  countLiquidated_not?: InputMaybe<Scalars['Int']>;
  countLiquidated_not_in?: InputMaybe<Array<Scalars['Int']>>;
  countLiquidator?: InputMaybe<Scalars['Int']>;
  countLiquidator_gt?: InputMaybe<Scalars['Int']>;
  countLiquidator_gte?: InputMaybe<Scalars['Int']>;
  countLiquidator_in?: InputMaybe<Array<Scalars['Int']>>;
  countLiquidator_lt?: InputMaybe<Scalars['Int']>;
  countLiquidator_lte?: InputMaybe<Scalars['Int']>;
  countLiquidator_not?: InputMaybe<Scalars['Int']>;
  countLiquidator_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hasBorrowed?: InputMaybe<Scalars['Boolean']>;
  hasBorrowed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  hasBorrowed_not?: InputMaybe<Scalars['Boolean']>;
  hasBorrowed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  tokens_?: InputMaybe<AccountVToken_Filter>;
};

export enum Account_OrderBy {
  CountLiquidated = 'countLiquidated',
  CountLiquidator = 'countLiquidator',
  HasBorrowed = 'hasBorrowed',
  Id = 'id',
  Tokens = 'tokens'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

/**
 * BorrowEvent stores information for borrows
 *
 */
export type BorrowEvent = UnderlyingTransfer & {
  __typename?: 'BorrowEvent';
  /** Total borrows of this asset the account has */
  accountBorrows: Scalars['BigDecimal'];
  /** Amount of underlying borrowed */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that borrowed the tokens */
  borrower: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Symbol of the borrowed underlying asset */
  underlyingSymbol: Scalars['String'];
};

export type BorrowEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  accountBorrows?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  accountBorrows_lt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_lte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<BorrowEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  borrower?: InputMaybe<Scalars['Bytes']>;
  borrower_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_gt?: InputMaybe<Scalars['Bytes']>;
  borrower_gte?: InputMaybe<Scalars['Bytes']>;
  borrower_in?: InputMaybe<Array<Scalars['Bytes']>>;
  borrower_lt?: InputMaybe<Scalars['Bytes']>;
  borrower_lte?: InputMaybe<Scalars['Bytes']>;
  borrower_not?: InputMaybe<Scalars['Bytes']>;
  borrower_not_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<BorrowEvent_Filter>>>;
  underlyingSymbol?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_lt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_lte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum BorrowEvent_OrderBy {
  AccountBorrows = 'accountBorrows',
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  Borrower = 'borrower',
  Id = 'id',
  UnderlyingSymbol = 'underlyingSymbol'
}

/**
 * The Comptroller type has protocol level variables stored
 *
 */
export type Comptroller = {
  __typename?: 'Comptroller';
  /** Factor used to determine repayAmount for liquidating */
  closeFactor: Scalars['BigInt'];
  /** ID is set to 1 */
  id: Scalars['ID'];
  /** The percent bonus liquidators get for liquidating */
  liquidationIncentive: Scalars['BigInt'];
  /** Max assets a single user can enter */
  maxAssets: Scalars['BigInt'];
  /** Address of price oracle the comptroller uses */
  priceOracle: Scalars['Bytes'];
};

export type Comptroller_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Comptroller_Filter>>>;
  closeFactor?: InputMaybe<Scalars['BigInt']>;
  closeFactor_gt?: InputMaybe<Scalars['BigInt']>;
  closeFactor_gte?: InputMaybe<Scalars['BigInt']>;
  closeFactor_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeFactor_lt?: InputMaybe<Scalars['BigInt']>;
  closeFactor_lte?: InputMaybe<Scalars['BigInt']>;
  closeFactor_not?: InputMaybe<Scalars['BigInt']>;
  closeFactor_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidationIncentive?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_gt?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_gte?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationIncentive_lt?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_lte?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_not?: InputMaybe<Scalars['BigInt']>;
  liquidationIncentive_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxAssets?: InputMaybe<Scalars['BigInt']>;
  maxAssets_gt?: InputMaybe<Scalars['BigInt']>;
  maxAssets_gte?: InputMaybe<Scalars['BigInt']>;
  maxAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxAssets_lt?: InputMaybe<Scalars['BigInt']>;
  maxAssets_lte?: InputMaybe<Scalars['BigInt']>;
  maxAssets_not?: InputMaybe<Scalars['BigInt']>;
  maxAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<Comptroller_Filter>>>;
  priceOracle?: InputMaybe<Scalars['Bytes']>;
  priceOracle_contains?: InputMaybe<Scalars['Bytes']>;
  priceOracle_gt?: InputMaybe<Scalars['Bytes']>;
  priceOracle_gte?: InputMaybe<Scalars['Bytes']>;
  priceOracle_in?: InputMaybe<Array<Scalars['Bytes']>>;
  priceOracle_lt?: InputMaybe<Scalars['Bytes']>;
  priceOracle_lte?: InputMaybe<Scalars['Bytes']>;
  priceOracle_not?: InputMaybe<Scalars['Bytes']>;
  priceOracle_not_contains?: InputMaybe<Scalars['Bytes']>;
  priceOracle_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum Comptroller_OrderBy {
  CloseFactor = 'closeFactor',
  Id = 'id',
  LiquidationIncentive = 'liquidationIncentive',
  MaxAssets = 'maxAssets',
  PriceOracle = 'priceOracle'
}

/**
 * LiquidationEvent stores information for liquidations. The event is emitted from
 * the vToken market address.
 *
 */
export type LiquidationEvent = VTokenTransfer & {
  __typename?: 'LiquidationEvent';
  /** vTokens seized */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account being liquidated (borrower) */
  from: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Liquidator receiving tokens */
  to: Scalars['Bytes'];
  /** Underlying vToken amount that was repaid by liquidator */
  underlyingRepayAmount: Scalars['BigDecimal'];
  /** Symbol of the underlying asset repaid through liquidation */
  underlyingSymbol: Scalars['String'];
  /** vToken that was sezied as collateral */
  vTokenSymbol: Scalars['String'];
};

export type LiquidationEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<LiquidationEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<LiquidationEvent_Filter>>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingRepayAmount?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingRepayAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  underlyingRepayAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingSymbol?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_lt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_lte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_lt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_lte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum LiquidationEvent_OrderBy {
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  From = 'from',
  Id = 'id',
  To = 'to',
  UnderlyingRepayAmount = 'underlyingRepayAmount',
  UnderlyingSymbol = 'underlyingSymbol',
  VTokenSymbol = 'vTokenSymbol'
}

/**
 * Market stores all high level variables for a vToken market
 *
 */
export type Market = {
  __typename?: 'Market';
  /** Block the market is updated to */
  accrualBlockNumber: Scalars['Int'];
  /** Timestamp the market was most recently updated */
  blockTimestamp: Scalars['Int'];
  /** The history of the markets borrow index return (Think S&P 500) */
  borrowIndex: Scalars['BigDecimal'];
  /** Borrow rate per block */
  borrowRate: Scalars['BigDecimal'];
  /** The vToken contract balance of BEP20 or BNB */
  cash: Scalars['BigDecimal'];
  /** Collateral factor determining how much one can borrow */
  collateralFactor: Scalars['BigDecimal'];
  /** Exchange rate of tokens / vTokens */
  exchangeRate: Scalars['BigDecimal'];
  /** VToken address */
  id: Scalars['ID'];
  /** Address of the interest rate model */
  interestRateModelAddress: Scalars['Bytes'];
  /** Name of the vToken */
  name: Scalars['String'];
  /** The factor determining interest that goes to reserves */
  reserveFactor: Scalars['BigInt'];
  /** Reserves stored in the contract */
  reserves: Scalars['BigDecimal'];
  /** Supply rate per block */
  supplyRate: Scalars['BigDecimal'];
  /** VToken symbol */
  symbol: Scalars['String'];
  /** Borrows in the market */
  totalBorrows: Scalars['BigDecimal'];
  /** VToken supply. VTokens have 8 decimals */
  totalSupply: Scalars['BigDecimal'];
  /** Underlying token address */
  underlyingAddress: Scalars['Bytes'];
  /** Underlying token decimal length */
  underlyingDecimals: Scalars['Int'];
  /** Underlying token name */
  underlyingName: Scalars['String'];
  /** Underlying price of token in BNB (ex. 0.007 DAI) */
  underlyingPrice: Scalars['BigDecimal'];
  /** Underlying token price in USD */
  underlyingPriceUSD: Scalars['BigDecimal'];
  /** Underlying token symbol */
  underlyingSymbol: Scalars['String'];
};

export type Market_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  accrualBlockNumber?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_gt?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_gte?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  accrualBlockNumber_lt?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_lte?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_not?: InputMaybe<Scalars['Int']>;
  accrualBlockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  and?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  blockTimestamp?: InputMaybe<Scalars['Int']>;
  blockTimestamp_gt?: InputMaybe<Scalars['Int']>;
  blockTimestamp_gte?: InputMaybe<Scalars['Int']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['Int']>;
  blockTimestamp_lte?: InputMaybe<Scalars['Int']>;
  blockTimestamp_not?: InputMaybe<Scalars['Int']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['Int']>>;
  borrowIndex?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_gt?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_gte?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  borrowIndex_lt?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_lte?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_not?: InputMaybe<Scalars['BigDecimal']>;
  borrowIndex_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  borrowRate?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  borrowRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_not?: InputMaybe<Scalars['BigDecimal']>;
  borrowRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  cash?: InputMaybe<Scalars['BigDecimal']>;
  cash_gt?: InputMaybe<Scalars['BigDecimal']>;
  cash_gte?: InputMaybe<Scalars['BigDecimal']>;
  cash_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  cash_lt?: InputMaybe<Scalars['BigDecimal']>;
  cash_lte?: InputMaybe<Scalars['BigDecimal']>;
  cash_not?: InputMaybe<Scalars['BigDecimal']>;
  cash_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  collateralFactor?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_gt?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_gte?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  collateralFactor_lt?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_lte?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_not?: InputMaybe<Scalars['BigDecimal']>;
  collateralFactor_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  exchangeRate?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  exchangeRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_not?: InputMaybe<Scalars['BigDecimal']>;
  exchangeRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  interestRateModelAddress?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_contains?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_gt?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_gte?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  interestRateModelAddress_lt?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_lte?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_not?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  interestRateModelAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  reserveFactor?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_gt?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_gte?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_in?: InputMaybe<Array<Scalars['BigInt']>>;
  reserveFactor_lt?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_lte?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_not?: InputMaybe<Scalars['BigInt']>;
  reserveFactor_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  reserves?: InputMaybe<Scalars['BigDecimal']>;
  reserves_gt?: InputMaybe<Scalars['BigDecimal']>;
  reserves_gte?: InputMaybe<Scalars['BigDecimal']>;
  reserves_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  reserves_lt?: InputMaybe<Scalars['BigDecimal']>;
  reserves_lte?: InputMaybe<Scalars['BigDecimal']>;
  reserves_not?: InputMaybe<Scalars['BigDecimal']>;
  reserves_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  supplyRate?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  supplyRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_not?: InputMaybe<Scalars['BigDecimal']>;
  supplyRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  totalBorrows?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalBorrows_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_not?: InputMaybe<Scalars['BigDecimal']>;
  totalBorrows_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalSupply?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalSupply_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_not?: InputMaybe<Scalars['BigDecimal']>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingAddress?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAddress_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_not_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAddress_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingDecimals?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_gt?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_gte?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_in?: InputMaybe<Array<Scalars['Int']>>;
  underlyingDecimals_lt?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_lte?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_not?: InputMaybe<Scalars['Int']>;
  underlyingDecimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  underlyingName?: InputMaybe<Scalars['String']>;
  underlyingName_contains?: InputMaybe<Scalars['String']>;
  underlyingName_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingName_ends_with?: InputMaybe<Scalars['String']>;
  underlyingName_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingName_gt?: InputMaybe<Scalars['String']>;
  underlyingName_gte?: InputMaybe<Scalars['String']>;
  underlyingName_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingName_lt?: InputMaybe<Scalars['String']>;
  underlyingName_lte?: InputMaybe<Scalars['String']>;
  underlyingName_not?: InputMaybe<Scalars['String']>;
  underlyingName_not_contains?: InputMaybe<Scalars['String']>;
  underlyingName_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingName_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingName_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingName_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingName_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingName_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingName_starts_with?: InputMaybe<Scalars['String']>;
  underlyingName_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingPrice?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingPriceUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPriceUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  underlyingPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingSymbol?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_lt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_lte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Market_OrderBy {
  AccrualBlockNumber = 'accrualBlockNumber',
  BlockTimestamp = 'blockTimestamp',
  BorrowIndex = 'borrowIndex',
  BorrowRate = 'borrowRate',
  Cash = 'cash',
  CollateralFactor = 'collateralFactor',
  ExchangeRate = 'exchangeRate',
  Id = 'id',
  InterestRateModelAddress = 'interestRateModelAddress',
  Name = 'name',
  ReserveFactor = 'reserveFactor',
  Reserves = 'reserves',
  SupplyRate = 'supplyRate',
  Symbol = 'symbol',
  TotalBorrows = 'totalBorrows',
  TotalSupply = 'totalSupply',
  UnderlyingAddress = 'underlyingAddress',
  UnderlyingDecimals = 'underlyingDecimals',
  UnderlyingName = 'underlyingName',
  UnderlyingPrice = 'underlyingPrice',
  UnderlyingPriceUsd = 'underlyingPriceUSD',
  UnderlyingSymbol = 'underlyingSymbol'
}

/**
 * MintEvent stores information for mints. From address will always be a vToken
 * market address
 *
 */
export type MintEvent = VTokenTransfer & {
  __typename?: 'MintEvent';
  /** vTokens transferred */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that sent tokens (VToken contract) */
  from: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Account that received tokens (minter) */
  to: Scalars['Bytes'];
  /** Underlying token amount transferred */
  underlyingAmount?: Maybe<Scalars['BigDecimal']>;
  /** Symbol of the vToken transferred */
  vTokenSymbol: Scalars['String'];
};

export type MintEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<MintEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<MintEvent_Filter>>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAmount?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  vTokenSymbol?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_lt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_lte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum MintEvent_OrderBy {
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  From = 'from',
  Id = 'id',
  To = 'to',
  UnderlyingAmount = 'underlyingAmount',
  VTokenSymbol = 'vTokenSymbol'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accountVToken?: Maybe<AccountVToken>;
  accountVTokenTransaction?: Maybe<AccountVTokenTransaction>;
  accountVTokenTransactions: Array<AccountVTokenTransaction>;
  accountVTokens: Array<AccountVToken>;
  accounts: Array<Account>;
  borrowEvent?: Maybe<BorrowEvent>;
  borrowEvents: Array<BorrowEvent>;
  comptroller?: Maybe<Comptroller>;
  comptrollers: Array<Comptroller>;
  liquidationEvent?: Maybe<LiquidationEvent>;
  liquidationEvents: Array<LiquidationEvent>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  mintEvent?: Maybe<MintEvent>;
  mintEvents: Array<MintEvent>;
  redeemEvent?: Maybe<RedeemEvent>;
  redeemEvents: Array<RedeemEvent>;
  repayEvent?: Maybe<RepayEvent>;
  repayEvents: Array<RepayEvent>;
  transferEvent?: Maybe<TransferEvent>;
  transferEvents: Array<TransferEvent>;
  underlyingTransfer?: Maybe<UnderlyingTransfer>;
  underlyingTransfers: Array<UnderlyingTransfer>;
  vtokenTransfer?: Maybe<VTokenTransfer>;
  vtokenTransfers: Array<VTokenTransfer>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountVTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountVTokenTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountVTokenTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVTokenTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AccountVTokenTransaction_Filter>;
};


export type QueryAccountVTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AccountVToken_Filter>;
};


export type QueryAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type QueryBorrowEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBorrowEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BorrowEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BorrowEvent_Filter>;
};


export type QueryComptrollerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryComptrollersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Comptroller_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Comptroller_Filter>;
};


export type QueryLiquidationEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidationEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidationEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationEvent_Filter>;
};


export type QueryMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type QueryMintEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMintEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MintEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MintEvent_Filter>;
};


export type QueryRedeemEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRedeemEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RedeemEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RedeemEvent_Filter>;
};


export type QueryRepayEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRepayEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RepayEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RepayEvent_Filter>;
};


export type QueryTransferEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransferEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferEvent_Filter>;
};


export type QueryUnderlyingTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUnderlyingTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnderlyingTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UnderlyingTransfer_Filter>;
};


export type QueryVtokenTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryVtokenTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VTokenTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VTokenTransfer_Filter>;
};

/**
 * RedeemEvent stores information for redeems. To address will always be a
 * vToken market address
 *
 */
export type RedeemEvent = VTokenTransfer & {
  __typename?: 'RedeemEvent';
  /** vTokens transferred */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that sent tokens (redeemer) */
  from: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Account that received tokens (VToken contract) */
  to: Scalars['Bytes'];
  /** Underlying token amount transferred */
  underlyingAmount?: Maybe<Scalars['BigDecimal']>;
  /** Symbol of the vToken transferred */
  vTokenSymbol: Scalars['String'];
};

export type RedeemEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<RedeemEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<RedeemEvent_Filter>>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAmount?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  underlyingAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  underlyingAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  vTokenSymbol?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_lt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_lte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum RedeemEvent_OrderBy {
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  From = 'from',
  Id = 'id',
  To = 'to',
  UnderlyingAmount = 'underlyingAmount',
  VTokenSymbol = 'vTokenSymbol'
}

/**
 * RepayEvent stores information for repays. Payer is not always the same as
 * borrower, such as in the event of a Liquidation
 *
 */
export type RepayEvent = UnderlyingTransfer & {
  __typename?: 'RepayEvent';
  /** Total borrows of this asset the account has */
  accountBorrows: Scalars['BigDecimal'];
  /** Amount of underlying repaid */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that borrowed the tokens */
  borrower: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Payer of the borrow funds */
  payer: Scalars['Bytes'];
  /** Symbol of the borrowed underlying asset */
  underlyingSymbol: Scalars['String'];
};

export type RepayEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  accountBorrows?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  accountBorrows_lt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_lte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<RepayEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  borrower?: InputMaybe<Scalars['Bytes']>;
  borrower_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_gt?: InputMaybe<Scalars['Bytes']>;
  borrower_gte?: InputMaybe<Scalars['Bytes']>;
  borrower_in?: InputMaybe<Array<Scalars['Bytes']>>;
  borrower_lt?: InputMaybe<Scalars['Bytes']>;
  borrower_lte?: InputMaybe<Scalars['Bytes']>;
  borrower_not?: InputMaybe<Scalars['Bytes']>;
  borrower_not_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<RepayEvent_Filter>>>;
  payer?: InputMaybe<Scalars['Bytes']>;
  payer_contains?: InputMaybe<Scalars['Bytes']>;
  payer_gt?: InputMaybe<Scalars['Bytes']>;
  payer_gte?: InputMaybe<Scalars['Bytes']>;
  payer_in?: InputMaybe<Array<Scalars['Bytes']>>;
  payer_lt?: InputMaybe<Scalars['Bytes']>;
  payer_lte?: InputMaybe<Scalars['Bytes']>;
  payer_not?: InputMaybe<Scalars['Bytes']>;
  payer_not_contains?: InputMaybe<Scalars['Bytes']>;
  payer_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingSymbol?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_lt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_lte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum RepayEvent_OrderBy {
  AccountBorrows = 'accountBorrows',
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  Borrower = 'borrower',
  Id = 'id',
  Payer = 'payer',
  UnderlyingSymbol = 'underlyingSymbol'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accountVToken?: Maybe<AccountVToken>;
  accountVTokenTransaction?: Maybe<AccountVTokenTransaction>;
  accountVTokenTransactions: Array<AccountVTokenTransaction>;
  accountVTokens: Array<AccountVToken>;
  accounts: Array<Account>;
  borrowEvent?: Maybe<BorrowEvent>;
  borrowEvents: Array<BorrowEvent>;
  comptroller?: Maybe<Comptroller>;
  comptrollers: Array<Comptroller>;
  liquidationEvent?: Maybe<LiquidationEvent>;
  liquidationEvents: Array<LiquidationEvent>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  mintEvent?: Maybe<MintEvent>;
  mintEvents: Array<MintEvent>;
  redeemEvent?: Maybe<RedeemEvent>;
  redeemEvents: Array<RedeemEvent>;
  repayEvent?: Maybe<RepayEvent>;
  repayEvents: Array<RepayEvent>;
  transferEvent?: Maybe<TransferEvent>;
  transferEvents: Array<TransferEvent>;
  underlyingTransfer?: Maybe<UnderlyingTransfer>;
  underlyingTransfers: Array<UnderlyingTransfer>;
  vtokenTransfer?: Maybe<VTokenTransfer>;
  vtokenTransfers: Array<VTokenTransfer>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountVTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountVTokenTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountVTokenTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVTokenTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AccountVTokenTransaction_Filter>;
};


export type SubscriptionAccountVTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AccountVToken_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AccountVToken_Filter>;
};


export type SubscriptionAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type SubscriptionBorrowEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBorrowEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BorrowEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<BorrowEvent_Filter>;
};


export type SubscriptionComptrollerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionComptrollersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Comptroller_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Comptroller_Filter>;
};


export type SubscriptionLiquidationEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidationEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidationEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationEvent_Filter>;
};


export type SubscriptionMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type SubscriptionMintEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMintEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MintEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MintEvent_Filter>;
};


export type SubscriptionRedeemEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRedeemEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RedeemEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RedeemEvent_Filter>;
};


export type SubscriptionRepayEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRepayEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RepayEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RepayEvent_Filter>;
};


export type SubscriptionTransferEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransferEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferEvent_Filter>;
};


export type SubscriptionUnderlyingTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUnderlyingTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnderlyingTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UnderlyingTransfer_Filter>;
};


export type SubscriptionVtokenTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionVtokenTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VTokenTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VTokenTransfer_Filter>;
};

/**
 * TransferEvent will be stored for every mint, redeem, liquidation, and any normal
 * transfer between two accounts.
 *
 */
export type TransferEvent = VTokenTransfer & {
  __typename?: 'TransferEvent';
  /** vTokens transferred */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that sent tokens */
  from: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Account that received tokens */
  to: Scalars['Bytes'];
  /** Symbol of the vToken transferred */
  vTokenSymbol: Scalars['String'];
};

export type TransferEvent_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<TransferEvent_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<TransferEvent_Filter>>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vTokenSymbol?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_lt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_lte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum TransferEvent_OrderBy {
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  From = 'from',
  Id = 'id',
  To = 'to',
  VTokenSymbol = 'vTokenSymbol'
}

/**
 * Underlying transfers are transfers of underlying collateral for both borrows
 * and repays
 *
 */
export type UnderlyingTransfer = {
  /** Total borrows of this asset the account has */
  accountBorrows: Scalars['BigDecimal'];
  /** Amount of underlying borrowed */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that borrowed the tokens */
  borrower: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Symbol of the borrowed underlying asset */
  underlyingSymbol: Scalars['String'];
};

export type UnderlyingTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  accountBorrows?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_gte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  accountBorrows_lt?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_lte?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not?: InputMaybe<Scalars['BigDecimal']>;
  accountBorrows_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<UnderlyingTransfer_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  borrower?: InputMaybe<Scalars['Bytes']>;
  borrower_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_gt?: InputMaybe<Scalars['Bytes']>;
  borrower_gte?: InputMaybe<Scalars['Bytes']>;
  borrower_in?: InputMaybe<Array<Scalars['Bytes']>>;
  borrower_lt?: InputMaybe<Scalars['Bytes']>;
  borrower_lte?: InputMaybe<Scalars['Bytes']>;
  borrower_not?: InputMaybe<Scalars['Bytes']>;
  borrower_not_contains?: InputMaybe<Scalars['Bytes']>;
  borrower_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<UnderlyingTransfer_Filter>>>;
  underlyingSymbol?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_gte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_lt?: InputMaybe<Scalars['String']>;
  underlyingSymbol_lte?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  underlyingSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with?: InputMaybe<Scalars['String']>;
  underlyingSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum UnderlyingTransfer_OrderBy {
  AccountBorrows = 'accountBorrows',
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  Borrower = 'borrower',
  Id = 'id',
  UnderlyingSymbol = 'underlyingSymbol'
}

/**
 * An interface for a transfer of any vToken. TransferEvent, MintEvent,
 * RedeemEvent, and LiquidationEvent all use this interface
 *
 */
export type VTokenTransfer = {
  /** vTokens transferred */
  amount: Scalars['BigDecimal'];
  /** Block number */
  blockNumber: Scalars['Int'];
  /** Block time */
  blockTime: Scalars['Int'];
  /** Account that sent tokens */
  from: Scalars['Bytes'];
  /** Transaction hash concatenated with log index */
  id: Scalars['ID'];
  /** Account that received tokens */
  to: Scalars['Bytes'];
  /** Symbol of the vToken transferred */
  vTokenSymbol: Scalars['String'];
};

export type VTokenTransfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<VTokenTransfer_Filter>>>;
  blockNumber?: InputMaybe<Scalars['Int']>;
  blockNumber_gt?: InputMaybe<Scalars['Int']>;
  blockNumber_gte?: InputMaybe<Scalars['Int']>;
  blockNumber_in?: InputMaybe<Array<Scalars['Int']>>;
  blockNumber_lt?: InputMaybe<Scalars['Int']>;
  blockNumber_lte?: InputMaybe<Scalars['Int']>;
  blockNumber_not?: InputMaybe<Scalars['Int']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime?: InputMaybe<Scalars['Int']>;
  blockTime_gt?: InputMaybe<Scalars['Int']>;
  blockTime_gte?: InputMaybe<Scalars['Int']>;
  blockTime_in?: InputMaybe<Array<Scalars['Int']>>;
  blockTime_lt?: InputMaybe<Scalars['Int']>;
  blockTime_lte?: InputMaybe<Scalars['Int']>;
  blockTime_not?: InputMaybe<Scalars['Int']>;
  blockTime_not_in?: InputMaybe<Array<Scalars['Int']>>;
  from?: InputMaybe<Scalars['Bytes']>;
  from_contains?: InputMaybe<Scalars['Bytes']>;
  from_gt?: InputMaybe<Scalars['Bytes']>;
  from_gte?: InputMaybe<Scalars['Bytes']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']>>;
  from_lt?: InputMaybe<Scalars['Bytes']>;
  from_lte?: InputMaybe<Scalars['Bytes']>;
  from_not?: InputMaybe<Scalars['Bytes']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<VTokenTransfer_Filter>>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  vTokenSymbol?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_gte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_lt?: InputMaybe<Scalars['String']>;
  vTokenSymbol_lte?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  vTokenSymbol_not_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with?: InputMaybe<Scalars['String']>;
  vTokenSymbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum VTokenTransfer_OrderBy {
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTime = 'blockTime',
  From = 'from',
  Id = 'id',
  To = 'to',
  VTokenSymbol = 'vTokenSymbol'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}


export const AssetsInAccountQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AssetsInAccountQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"accountAddress"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"accountAddress"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tokens"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"market"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<AssetsInAccountQueryQuery, AssetsInAccountQueryQueryVariables>;
export const MarketsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MarketsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"borrowRate"}},{"kind":"Field","name":{"kind":"Name","value":"cash"}},{"kind":"Field","name":{"kind":"Name","value":"collateralFactor"}},{"kind":"Field","name":{"kind":"Name","value":"exchangeRate"}},{"kind":"Field","name":{"kind":"Name","value":"interestRateModelAddress"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"reserves"}},{"kind":"Field","name":{"kind":"Name","value":"supplyRate"}},{"kind":"Field","name":{"kind":"Name","value":"symbol"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"totalBorrows"}},{"kind":"Field","name":{"kind":"Name","value":"totalSupply"}},{"kind":"Field","name":{"kind":"Name","value":"reserves"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingAddress"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingName"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingPrice"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingSymbol"}},{"kind":"Field","name":{"kind":"Name","value":"accrualBlockNumber"}},{"kind":"Field","name":{"kind":"Name","value":"blockTimestamp"}},{"kind":"Field","name":{"kind":"Name","value":"borrowIndex"}},{"kind":"Field","name":{"kind":"Name","value":"reserveFactor"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingPriceUSD"}},{"kind":"Field","name":{"kind":"Name","value":"underlyingDecimals"}}]}}]}}]} as unknown as DocumentNode<MarketsQueryQuery, MarketsQueryQueryVariables>;
export type AssetsInAccountQueryQueryVariables = Exact<{
  accountAddress: Scalars['ID'];
}>;


export type AssetsInAccountQueryQuery = { __typename?: 'Query', account?: { __typename?: 'Account', tokens: Array<{ __typename?: 'AccountVToken', market: { __typename?: 'Market', id: string } }> } | null };

export type MarketsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketsQueryQuery = { __typename?: 'Query', markets: Array<{ __typename?: 'Market', borrowRate: any, cash: any, collateralFactor: any, exchangeRate: any, interestRateModelAddress: any, name: string, reserves: any, supplyRate: any, symbol: string, id: string, totalBorrows: any, totalSupply: any, underlyingAddress: any, underlyingName: string, underlyingPrice: any, underlyingSymbol: string, accrualBlockNumber: number, blockTimestamp: number, borrowIndex: any, reserveFactor: any, underlyingPriceUSD: any, underlyingDecimals: number }> };

export const subgraphEndpoint = 'https://api.thegraph.com/subgraphs/name/999max/tonpoundmumbai';